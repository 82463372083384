<!--便捷时间选择器-->
<template>
    <div>
        <div class="selector-wrapper">
            <div class="selector-label-wrapper">
                <div class="selector-label" :class="currentLabel==0 ? 'selector-active' : ''" @click="clickLabel(0,0)">今天</div>
                <div class="selector-label" :class="currentLabel==-1 ? 'selector-active' : ''" @click="clickLabel(-1,-1)">昨天</div>
                <div class="selector-label" :class="currentLabel==-6 ? 'selector-active' : ''" @click="clickLabel(-6,0)">近七天</div>
            </div>
            <div class="selector-date-wrapper">
                <input class="selector-date-input" type="text" v-model="startTime" @click="show = true"/>
                <div class="selector-font">至</div>
                <input class="selector-date-input" type="text" v-model="endTime" @click="show = true"/>
            </div>
        </div>
        <van-calendar v-model="show" :min-date="minDate" :default-date="computedDefaultDate" type="range" @confirm="onConfirm" />
    </div>
</template>

<script>
  import { getStandardDate, formatGMT2StandardDate } from '../../utils/tools'
  export default {
    name: 'TimeRangeSelector',
    data () {
      return {
        minDate: new Date(2010, 0, 1),
        startTime: '',
        endTime: '',
        show: false,
        currentLabel: 0
      }
    },
    methods: {
      // 日历选择回调函数
      onConfirm (date) {
        const [start, end] = date
        this.startTime = formatGMT2StandardDate(start)
        this.endTime = formatGMT2StandardDate(end)
        this.currentLabel = 99
        this.choiceTime()
        this.show = false
      },
      // 点击快捷搜索标签
      clickLabel (start, end) {
        this.currentLabel = start
        this.startTime = getStandardDate(start)
        this.endTime = getStandardDate(end)
        this.choiceTime()
      },
      // 选择时间
      choiceTime () {
        this.$emit('refreshTime', this.startTime, this.endTime)
      },
      // 字符串转成Date对象
      str2Date (str) {
        const arr = str.split('-')
        return new Date(arr[0], arr[1] - 1, arr[2])
      }
    },
    computed: {
      computedDefaultDate: function () {
        return [this.str2Date(this.startTime), this.str2Date(this.endTime)]
      }
    },
    mounted () {
      this.startTime = this.endTime = getStandardDate(0)
      this.choiceTime() // 初始化，冒泡到主组件
      console.log(this.computedDefaultDate)
    }
  }
</script>

<style scoped>
    .selector-wrapper{
        width: 100vw;
        display: flex;
        background-color: #FFFFff;
        box-shadow:0 8px 10px -8px #999;
        padding: 0.5rem 0;
    }
    .selector-label-wrapper{
        width: 45%;
        display: flex;
        color: #999;
        font-size: 3vw;
        padding: 3vw 1vw;
        box-sizing: border-box;
    }
    .selector-label{
        background: #f8f8f8;
        padding: 0.25vw 2vw;
        border-radius: 4vw;
        margin: 0 1.2vw;
    }
    .selector-active{
        background: #FFE8D8;
        padding: 0.2vw 2vw;
        border-radius: 4vw;
        color: #ff4949;
        margin: 0 1.2vw;
    }
    .selector-date-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 1rem 0 0;
        width: 55%;
        box-sizing: border-box;
    }
    .selector-date-input{
        width: 20vw;
        height: 5vw;
        border-radius: 5vw;
        border: 1px solid #b8b8b8;
        font-size: 2.8vw;
        text-align: center;
    }
    .selector-font{
        margin: 0 1vw;
        font-size: 3.2vw;
        color: #333;
    }
</style>
